import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import(/* webpackChunkName: "about" */ '../views/index.vue')
  },
  {
    path: '/socket',
    name: 'socket',
    component: () => import(/* webpackChunkName: "about" */ '../views/socket.vue')
  },
  {
    path: '/push',
    name: 'push',
    component: () => import(/* webpackChunkName: "about" */ '../views/push.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
