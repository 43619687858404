import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 引入 element
import ElementUI,  {Message, MessageBox}  from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);


// 提示封装
Vue.prototype.$Message = opt => {
  Message(opt);
}
Vue.prototype.$errorMessage = (message, duration = 3000) => {
  Message({
    message,
    duration,
    type: 'error',
  })
}
Vue.prototype.$successMessage = (message, duration = 3000) => {
  Message({
    message,
    duration,
    type: 'success',
  })
}
Vue.prototype.$warning = (message) => {
  return new Promise((resolve, reject) => {
    MessageBox.confirm(message, '提示', {
      customClass: 'system-message-box',
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      resolve();
    }).catch(() => {
      reject();
    });
  })
}

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
